<template>
     <div class="col-12 col-lg-11 d-flex justify-content-center align-items-center perfil mt-lg-5">
        <div class="row justify-content-center row__width">
            <div class="col-12 col-lg-2 d-flex justify-content-center align-items-center px-0 mb-lg-0 ps-lg-auto img-change-mobile">
                <div class="rates d-lg-none">
                    <div class="gris" v-if="ranking">
                        <p class="position">TOP {{ ranking.position }}</p>
                    </div>
                </div> 
                <span class="box">
                    <img :src="userPic" alt="avatar" class="ejemplo2">
                </span>
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center px-0 d-lg-none">
                <div class="row row__width">
                    <template v-if="average != 0">
                        <div class="col d-flex justify-content-center align-items-center mb-3 rates">
                            <img src="../../img/home-icons/cursos-icons/rate.svg" alt="" class="image-fix" v-for="rate in Math.floor(average)" :key="`rate-${rate}`">
                            <img src="../../img/home-icons/cursos-icons/rate-gris.svg" alt="" class="image-fix" v-for="rateGris in 5 - Math.floor(average)" :key="`rate-gris-${rateGris}`">
                            <p class="average">{{average}}</p>
                            <p >{{ `(${total})` }}</p>
                        </div>
                    </template>
                    <template v-else>
                        <div class="col d-flex justify-content-center align-items-center mb-3 rates">
                            <img src="../../img/home-icons/cursos-icons/rate.svg" alt="" class="image-fix">
                            <p>{{ $t('message.new') }}</p>
                        </div>
                    </template>
                    <div class="col-12 d-flex justify-content-center align-items-center">
                        <h1 class="mb-3 name">{{ userName }}<!-- <img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="" class="" v-if="verified != false"> --></h1>
                    </div>
                </div> 
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center px-0 d-lg-none">
                <div class="row justify-content-center row__width">
                    <div class="col-12 d-flex justify-content-center align-items-center mb-2 mb-lg-3 pe-lg-0 speaker">
                        <p v-if="role" class="me-3 average">
                            <img src="../../img/perfil-icons/speaker.svg" alt="speaker_icon">
                            Speaker Master Class
                        </p>
                        <p class=" spec" v-if="profession && profession.translation && profession.translation.name">
                            <img src="../../img/perfil-icons/cv.svg" alt="prof_icon">
                            {{ profession.translation.name }}
                        </p>
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center mb-1 mb-lg-3 pe-0 speaker">
                        <template v-if="profession && profession.specializations">
                            <p class="me-1 spec" v-for="(specialization, id) in profession.specializations.slice(0, 2)" :key="id">
                                <img src="../../img/perfil-icons/cv.svg" alt="prof_icon">
                                {{ specialization.translation.name }}
                            </p>
                        </template>
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center mb-3 pe-lg-0">
                        <p class="country">
                            <template v-if="country?.translation?.name">
                                {{ countryCodeToFlagEmoji(country.code) }} {{ country.translation.name }},
                            </template>
                            <template v-if="state?.translation?.name">
                                {{ state.translation.name }},
                            </template>
                            <template v-if="city?.translation?.name">
                                {{ city.translation.name }}
                            </template>
                        </p>
                    </div>
                    <div class="col-12 d-flex justify-content-center align-items-center mb-lg-4 social-network" v-if="social_network != null">
                        <template v-if="contact.phone.code || contact.phone.number">
                            <a :href="`https://wa.me/${contact.phone.code}${contact.phone.number}`" target="_blank">
                                <img src="../../img/perfil-icons/wpp.svg" alt="">
                            </a>    
                        </template>
                        <!-- {{ social_network }} -->
                          
                        <template v-for="(url, name) in social_network" :key="name">
                            <a v-if="name.toLowerCase() == 'facebook'" :href="url"   target="_blank">
                                <img src="../../img/perfil-icons/facebook.svg" :alt="name">
                            </a>
                            <a v-if="name.toLowerCase() == 'instagram'" :href="url"   target="_blank">
                                <img src="../../img/perfil-icons/instagram.svg" :alt="name"> 
                            </a>
                            <a v-if="name.toLowerCase() == 'twitter'" :href="url"   target="_blank">
                                <img src="../../img/perfil-icons/twitter.svg" :alt="name" >
                            </a>
                            <a v-if="name.toLowerCase() == 'linkedin'" :href="url"   target="_blank">
                                <img src="../../img/perfil-icons/linkedin.svg" :alt="name">
                            </a>
                            <a v-if="name.toLowerCase() == 'pinterest'" :href="url"   target="_blank">
                                <img src="../../img/perfil-icons/pinterest.svg" :alt="name">
                            </a>
                            <a v-if="name.toLowerCase() == 'web'" :href="url"   target="_blank">
                                <img src="../../img/perfil-icons/download.svg" :alt="name">
                            </a>
                        </template>
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-10 d-flex justify-content-center align-items-center align-items-lg-start px-0 d-none d-lg-flex">
                <div class="row justify-content-center row__width">
                    <div class="col-12 d-flex justify-content-between align-items-center mb-2 mb-lg-0 pe-0">
                        <h1 class="mb-3 name">{{ userName }}<!-- <img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="" class="" v-if="verified != false"> --></h1> 
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center mb-2 mb-lg-3 pe-0 speaker">
                        <p v-if="role" class="me-3 average">
                            <img src="../../img/perfil-icons/speaker.svg" alt="speaker_icon">
                            Speaker Master Class
                        </p>
                        <p class=" spec" v-if="profession && profession.translation && profession.translation.name">
                            <img src="../../img/perfil-icons/cv.svg" alt="prof_icon">
                            {{ profession.translation.name }}
                        </p>
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center mb-2 mb-lg-3 pe-0 speaker">
                        <template v-if="profession && profession.specializations">
                            <p class="me-1 spec">
                                <img src="../../img/perfil-icons/cv.svg" alt="prof_icon">
                                <template v-for="(specialization, id) in profession.specializations" :key="id">
                                    {{ specialization.translation.name }}<template v-if="profession.specializations.length - 1 != id">, </template>
                                </template>
                            </p>
                        </template>
                    </div>

                    <div class="col-12 d-flex justify-content-start align-items-center mb-3 pe-lg-0">
                        <p class="country">
                            <template v-if="country?.translation?.name">
                                {{ countryCodeToFlagEmoji(country.code) }} {{ country.translation.name }},
                            </template>
                            <template v-if="state?.translation?.name">
                                {{ state.translation.name }},
                            </template>
                            <template v-if="city?.translation?.name">
                                {{ city.translation.name }}
                            </template>
                        </p>
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center mb-lg-4 social-network" v-if="social_network != null">
                        <template v-if="contact.phone.code || contact.phone.number">
                            <a :href="`https://wa.me/${contact.phone.code}${contact.phone.number}`" target="_blank">
                                <img src="../../img/perfil-icons/wpp.svg" alt="">
                            </a>    
                        </template>
                        <!-- {{ social_network }} -->
                          
                        <template v-for="(url, name) in social_network" :key="name">
                            <a v-if="name.toLowerCase() == 'facebook'" :href="ensureHttps(url)" target="_blank">
                                <img src="../../img/perfil-icons/facebook.svg" :alt="name">
                            </a>
                            <a v-if="name.toLowerCase() == 'instagram'" :href="ensureHttps(url)" target="_blank">
                                <img src="../../img/perfil-icons/instagram.svg" :alt="name"> 
                            </a>
                            <a v-if="name.toLowerCase() == 'twitter'" :href="ensureHttps(url)" target="_blank">
                                <img src="../../img/perfil-icons/twitter.svg" :alt="name" >
                            </a>
                            <a v-if="name.toLowerCase() == 'linkedin'" :href="ensureHttps(url)" target="_blank">
                                <img src="../../img/perfil-icons/linkedin.svg" :alt="name">
                            </a>
                            <a v-if="name.toLowerCase() == 'pinterest'" :href="ensureHttps(url)" target="_blank">
                                <img src="../../img/perfil-icons/pinterest.svg" :alt="name">
                            </a>
                            <a v-if="name.toLowerCase() == 'web'" :href="ensureHttps(url)" target="_blank">
                                <img src="../../img/perfil-icons/download.svg" :alt="name">
                            </a>
                        </template>
                        
                    </div>
                </div>
                <div class="row justify-content-end row__width me-3 ">
                    <div class="col-12 d-flex justify-content-end align-items-center py-1 mb-5 rates">
                        <div class="gris" v-if="ranking">
                            <p class="position">TOP {{ ranking.position }}</p>
                        </div>
                        <template v-if="average != 0">
                            <img src="../../img/home-icons/cursos-icons/rate.svg" alt="" class="image-fix" v-for="rate in Math.floor(average)" :key="`rate-${rate}`">
                            <img src="../../img/home-icons/cursos-icons/rate-gris.svg" alt="" class="image-fix" v-for="rateGris in 5 - Math.floor(average)" :key="`rate-gris-${rateGris}`">
                            <p class="average">{{average}}</p>
                            <p >{{ `(${total})` }}</p>
                        </template>
                        <template v-else>
                            <img src="../../img/home-icons/cursos-icons/rate.svg" alt="" class="image-fix">
                            <p>{{ $t('message.new') }}</p>
                        </template>
                    </div> 
                    <div class="col-6 d-flex justify-content-start align-items-center mb-3 d-none pe-0"> <!--- d-lg-flex  --->
                        <a href="" class="btn__secundario">
                            <span class="icon"></span>
                            {{ $t('message.contactV2') }}
                        </a>
                    </div>
                </div>
            </div>
            <!-- <div class="col-12 d-flex justify-content-start align-items-center mb-3 d-lg-none">
                <a href="" class="btn__primario btn__secundario"><img src="../../img/panel-icons/comentarios.svg" alt="">  {{ $t('message.contact') }}</a>
            </div> -->
        </div>
    </div>
</template>

<script setup>
    import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
    import { onMounted } from "vue";

    const props = defineProps({
        userName: '',
        userPic: '',
        userBanner: '',
        country: '',
        state: '',
        city: '',
        userWeb: '',
        ranking: '',
        verified: '',
        average: '',
        total: '',
        social_network: '',
        profession: '',
        role: '',
        contact: {}
    })

    function countryCodeToFlagEmoji(countryCode) {
        return countryCode
            .toUpperCase()
            .replace(/./g, char => String.fromCodePoint(127397 + char.charCodeAt()));
    }

    function ensureHttps(url) {
        if (!url.startsWith('https://')) {
            return 'https://' + url;
        }
        return url;
    }

    onMounted(() => {
        polyfillCountryFlagEmojis();
    });
</script>

<style lang="scss" scoped>
.perfil{
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 15px;
    background-color: #FFFFFF;
    padding: 10px 5px;
    padding-top: 20px;
    min-height: 20vh;
    position: relative;
    margin-top: 100px;
    h1{
        font-weight: 700;
        font-size: 20px;
        color: #374557;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-left: 5px;
        img{
            padding-left: 5px;
        }
        @media (min-width: 992px) {
            font-size: 22px;
            padding-left: 0;
        }
    }
    .span{
        box-shadow: 0px 3px 15px #00000029;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .country{
        font-weight: 400;
        font-size: 13px;
        color: #9da5bb;
        margin-left: 5px;
        text-align: left;
    }
    .spec{
        font-weight: 400;
        font-size: 16px;
        margin-left: 5px;
        text-align: left;
    }
    .average{
        font-weight: 500;
        font-size: 16px;
        margin-left: 5px;
        text-align: left;
    }
    .a{
        font-weight: 400;
        font-size: 13px;
        color: #B8C1DB;
        margin-left: 5px;
        text-decoration: underline;
        text-align: left;
    }
    .gris{
        background-color: transparent;
        border-radius: 24px;
        border: 2px solid var(--colorPrimary);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 7px;
        margin-right: 7px;
        h4{
            color: #FEB230;
            font-weight: 500;
            font-family: 'Proxima Nova';
            font-size: 16px;
            padding-top: 3px;
            padding-left: 3px;
        }
        h5{
            color: #707070;
            font-weight: 400;
            font-family: 'Proxima Nova';
            font-size: 16px;
            padding-top: 3px;
            padding-left: 3px;
        }
        img{
            height: 15px;
        }
        .position{
            font-weight: 600;
            color: var(--colorPrimary);
        }
        @media (max-width: 992px) {
            margin-right: 0;
        }
    }
    .box{
        box-shadow: 0px 3px 6px #00000029;
        border: 2px solid #FFFFFF;
        height: 120px;
        width: 120px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: -5%;
        background-color: #fff;
        img{
            width: 100%;
            height: 100%;
        }
        @media (min-width: 992px) {
            z-index: 2;
            height: 150px;
            width: 150px;
            
        }
    }
    .btn__secundario{
        .icon{
            display: inline-block;
            width: 24px;
            height: 24px;
            background-image: url('../../img/panel-icons/comentarios.svg');
            background-size: cover;
            margin-right: 8px;
        }
        &:hover .icon{
            background-image: url('../../img/panel-icons/comentarios-blue.svg');
        }
        .icon{
            padding-right: 10px;
        }
    }
    @media (min-width: 992px) {
        border-radius: 20px ;
        h2{
            font-size: 30px;
            color: var(--colorSecondary);
            img{
                height: 25px;
            }
        }
        h3{
            font-size: 16px;
        }
        .span{
            height: 45px;
            width: 45px;
            img{
                height: 50%;
            }
        }
        .borde__left{
            border-left: 1px solid #70707081;
        }
        .iconos{
            .web{
                height: 25px;
            }
            .bandera{
                height: 15px;
            }
        }
    }
}
.ejemplo2 {
    border-radius: 50%;
    object-fit: cover;
}
.social-network{
    a{
        margin-left: 7px;
        border-radius: 50%;
        img{
            height: 30px;
            width: 30px;
            padding-right: 5px;
        }
    }
    .border-box{
        background-color: #fff;
        box-shadow: 0px 3px 10px #00000029;
    }
}

.rates{
    img, .average, p{
        margin-left: 2px;
        margin-right: 2px;
    }
    img{
        margin-bottom: 2px;
        margin-left: 3px;
        margin-right: 3px;
    }
    .average{
        margin-top: 1px;
        color: #FEB230;
    }
    img{
        height: 15px;
        width: 15px;
    }
}

.speaker{
    display: flex;
    @media (max-width: 992px) {
        flex-direction: column;
        .spec{
            margin-bottom: 5px !important;
        }
    }
    img{
        margin-bottom: 2px;
        margin-right: 5px;
    }
    h4{
        font-size: 14px;
    }
}

.flex-change{
 @media (max-width: 992px) {
    flex-direction: column;
 }
}
.img-change-mobile{
    @media (max-width: 992px) {
        padding: 30px 0;
        
        .box{
            position: absolute;
            top: -30%;
        }
        .rates{
            position: absolute;
            top: 13%;
            z-index: 2;
            .gris{
                background-color: var(--colorPrimary);
                img{
                    height: 15px;
                }
                h6{
                    font-weight: 600;
                    color: #fff;
                }
            }
        }
    }
}
</style>