<template>
     <div class="col-11 d-flex flex-column justify-content-center align-items-center tablas px-0">
        <div class="scroll d-flex justify-content-start align-items-center row__width">
            <ul class="nav nav-tabs row justify-content-start" id="myTab" role="tablist">
                <li class="nav-item col d-flex justify-content-center align-items-center" v-if="professional.about">
                    <button class="nav-link" type="button" @click="scroll(1)">
                        <i class="perfil"></i>
                        <p>{{ $t('message.profile') }}</p>
                    </button>
                </li>
                <li class="nav-item col d-flex justify-content-center align-items-center" v-if="professional.gallery?.specializations.length > 0">
                    <button class="nav-link" type="button" @click="scroll(2), actual_gallery = null">
                        <i class="galerias"></i>
                        <p>{{ $t('message.galleries') }}</p>
                    </button>
                </li>
                <li class="nav-item col d-flex justify-content-center align-items-center" v-if="professional.products && professional.products.courses">
                    <button class="nav-link"  type="button" @click="scroll(4)">
                        <i class="courses"></i>
                        <p>{{ $t('message.courses') }}</p>
                    </button>
                </li>
                <li class="nav-item col d-flex justify-content-center align-items-center" >
                    <button class="nav-link" type="button" @click="scroll(3)">
                        <i class="reviews"></i>
                        <p>{{ $t('message.reviews') }}</p>
                    </button>
                </li>
                
                <!-- <li class="nav-item col d-flex justify-content-center align-items-center" v-if="professional.products && professional.products.downloadables">
                    <button class="nav-link" type="button" @click="scroll(5)">
                        <i class="download"></i>
                        <h3>{{ $t('message.downloadable') }}</h3>
                    </button>
                </li> -->
                <!-- <li class="nav-item col d-flex justify-content-center align-items-center">
                    <button class="nav-link" :class="actual_tab == 6 ? 'active' : ''" type="button" @click="actual_tab = 6"><h3>{{ $t('message.presets') }}</h3><i class="presets"></i></button>
                </li> -->
            </ul>
        </div>
    </div>
</template>

<script setup>
import { inject } from 'vue';



const actual_tab = inject('actual_tab')
const actual_gallery = inject('actual_gallery')

const professional = inject('professional')

const scroll = (id) => {
    let element = document.getElementById(id);
    if (!element) {
        return;
    }

    // Obtener la posición absoluta del elemento respecto al inicio de la página
    let elementTop = element.getBoundingClientRect().top + window.scrollY;
    console.log('tab-' + id, element, elementTop);

    // Ajustar el desplazamiento según el ancho de la ventana
    let offset = window.innerWidth >= 992 ? 80 : 80;

    // Realizar el scroll
    window.scroll({
        top: elementTop - offset, // Siempre va al inicio del elemento ajustado por el offset
        left: 0,
        behavior: 'smooth'
    });
};
    
</script>

<style lang="scss" scoped>
.tablas{  
    margin-top: 10px;
    background-color: transparent;
    min-height: 56px;
    border-bottom: 1px solid #70707023;
    ul{
        background-color: transparent;
        padding: 8px 0;
    }
    .nav-item{
        padding: 0;
    }
    .col{
        max-width: max-content;
    }
    .nav-link{
        width: 100%;
        position: relative;
        padding: 10px 0;
        border: 0;
        background-color: transparent;
        border-right: 1px solid #70707023;
        border-radius: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        color: #B8C1DB;
        p{
            display: flex;
            font-weight: 500;
            font-size: 16px;
            color: #B8C1DB;
            padding-left: 5px;
        }
        i{
            height: 25px;
            width: 30px;
            background-repeat: no-repeat;
            background-position: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .perfil{
            background-image: url('../../img/perfil-icons/perfil-icon.svg');
        }
        .galerias{
            background-image: url('../../img/perfil-icons/gallery.svg');
        }
        .reviews{
            background-image: url('../../img/perfil-icons/review.svg');
        }
        .courses{
            background-image: url('../../img/perfil-icons/courses.svg');
        }
        .download{
            background-image: url('../../img/perfil-icons/download.svg');
            width: 25px;
        }
        .presets{
            background-image: url('../../img/perfil-icons/presets.svg');
        }
        @media (min-width: 992px) {
            color: #0E1133;
           
        }
    }
    .nav-link.active{
        font-weight: 700;
        //border-color: transparent;
        color: var(--colorSecondary);
        background-color: transparent;
        border-radius: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        &::before{
            content: '';
            position: absolute;
            width: 70%;
            height: 5px;
            bottom: -10px;
            background-color: var(--colorPrimary);
            
        }
        p{
            display: flex;
            font-weight: 500;
            font-size: 16px;
            color: #B8C1DB;
            padding-right: 5px;
        }
        
        // i{
        //     display: none;
        // }
       
        @media (min-width: 992px) {
            &::before{
                height: 4px;
            }   
            p{
                color: #0E1133;
            }
        }
    }
    .scroll{
        overflow-x: scroll;
        overflow-y: hidden;
        max-width: 100%;
        height: 100%;
        ul{
            min-width: max-content;
            height: 100%;
            button{
                height: 100%;
                min-width: 150px;
            }
        }
        @media (min-width: 992px) {
            overflow: hidden;
            max-width: none;
            width: 100%;
            ul{
                max-width: 100%;
                min-width: 1vw;
                width: 100%;
            }
        }
    }
    @media (min-width: 992px) {
        background-color: #fff;
        box-shadow: 0px 3px 10px #00000029;
        border-radius: 10px;
    }
}
</style>